import { getRedirectUrl } from "@99tech/shared-utils";
import { useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  FLOATING_BANNER_URL,
  FallbackRefCode,
  LOGIN_URL,
  LOGIN_URL_MAP,
  REGISTER_URL,
  REGISTER_URL_MAP,
  RefCodeMap,
} from "../constants";
import { addParams } from "../utils";
import useFbcid from "./useFbcid";

const useUrls = () => {
  const [urls, setUrls] = useState(["", "", FallbackRefCode[0]]);
  const [params] = useSearchParams();
  const { fbcid } = useFbcid();

  useLayoutEffect(() => {
    const hostname = window.location.hostname.replace(/^www\./, "");
    const [refCode, pixelId] = RefCodeMap[hostname] ?? FallbackRefCode;

    const code = params.has("code") ? params.get("code") ?? refCode : refCode;
    const pixel = params.has("pixel") ? params.get("pixel") ?? pixelId : pixelId;

    const urlParams = {
      id: code,
      fb_dynamic_pixel: typeof pixel === "string" ? pixel : undefined,
      fbcid: fbcid ? fbcid : undefined,
    };

    const loginUrl = addParams(LOGIN_URL_MAP[hostname] ?? LOGIN_URL, { ...urlParams });
    const registerUrl = addParams(REGISTER_URL_MAP[hostname] ?? REGISTER_URL, { ...urlParams });

    const floatingBannerUrl = getRedirectUrl(FLOATING_BANNER_URL, fbcid, params, RefCodeMap);

    setUrls([loginUrl, registerUrl, floatingBannerUrl, code]);
  }, [params, fbcid]);

  return {
    loginUrl: urls[0] as string,
    registerUrl: urls[1] as string,
    floatingBannerUrl: urls[2] as string,
    refCode: urls[3] as string,
  };
};

export default useUrls;
