import { Box, Container, ContainerProps } from "@mui/material";
import React from "react";
import { createStyles } from "src/main/utils";

interface ContentProps extends React.PropsWithChildren, ContainerProps {}

const Content: React.FC<ContentProps> = (props) => {
  const { children, ...containerProps } = props;

  return (
    <Box sx={styles.root}>
      <Container
        {...containerProps}
        sx={styles.container}
      >
        {children}
      </Container>
    </Box>
  );
};

const styles = createStyles({
  root: {
    backgroundColor: "black",
    minHeight: "100vh",
  },
  container: {
    height: "100%",
    paddingX: 0,
    maxWidth: "560px",
  },
});

export default Content;
