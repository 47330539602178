import { VisuallyHidden } from "@99tech/ui";
import { Box, Stack } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { Bg, CharsBack, CharsMid, Coins, Equipments, Glare, LoginButton, Logo, RegisterButton } from "src/assets";
import { FloatingBanner } from "src/main/components";
import { AppContext } from "src/main/constants";
import { createStyles, isMobile } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  const { urls } = useContext(AppContext);
  const { loginUrl, registerUrl } = urls ?? {};

  const handleClick = useCallback(
    (type: "login" | "register") => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (type === "login") {
        window.open(loginUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      } else {
        window.open(registerUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      }
    },
    [loginUrl, registerUrl],
  );

  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">Dee99</VisuallyHidden>
      <img
        alt="bg"
        src={Bg}
        style={{
          position: "absolute",
          top: 70,
          width: 423,
          animation: `bgmove 10s linear infinite`,
        }}
      />

      <Box sx={{ position: "relative", width: 360, textAlign: "center" }}>
        <img
          alt="logo"
          src={Logo}
          style={{ width: 156 }}
        />
      </Box>

      <Stack
        direction="row"
        zIndex={10}
        mt={-1}
      >
        <a
          href={registerUrl}
          onClick={handleClick("register")}
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="register"
            src={RegisterButton}
            height={50}
          />
        </a>
        <a
          href={loginUrl}
          onClick={handleClick("login")}
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="login"
            src={LoginButton}
            height={50}
          />
        </a>
      </Stack>

      <Box sx={styles.images}>
        <img
          alt="chars back"
          src={CharsBack}
          style={{
            animation: "charsback 1.28s linear 0.8s infinite",
            width: 305,
            left: 0,
            right: 0,
            margin: "0 auto",
            top: 40,
          }}
        />
        <img
          alt="chars mid"
          src={CharsMid}
          style={{
            animation: "charsmid 1.28s linear 0.8s infinite",
            width: 240,
            left: 50,
            top: 10,
          }}
        />
        <img
          alt="equipments"
          src={Equipments}
          style={{
            animation: "equipments 1.28s linear 0.8s infinite",
            width: 284,
            top: 230,
            left: 31,
          }}
        />
        <img
          alt="glare"
          src={Glare}
          style={{
            animation: "glare 1.28s linear 0.8s infinite",
            // width: 250,
            top: 210,
            left: 75,
          }}
        />
        <img
          alt="coins"
          src={Coins}
          style={{
            animation: "coins 1.28s linear 0.8s infinite",
            width: 355,
            top: -14,
            left: -4,
            right: 0,
            margin: "0 auto",
          }}
        />
      </Box>

      <FloatingBanner />
    </Box>
  );
};

const styles = createStyles({
  root: {
    pt: "46px",
    pb: "20px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
  },
  images: {
    zIndex: 10,
    position: "relative",
    width: "360px",
    height: "440px",
    img: {
      position: "absolute",
    },
    animation: "fadeBottom 0.8s ease-out",
  },
  footer: {
    zIndex: 0,
    position: "fixed",
    bottom: "0",
    width: "100vw",
    height: "200px",
    flexShrink: 0,
    background: "radial-gradient(800px 100% at 50% 100%, #FFF9C1 0%, #21966C 19%, rgba(0, 0, 0, 0.00) 50%)",
  },
});

export default LandingPage;
