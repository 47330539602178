import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const DEFAULT_URL = "";

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const LOGIN_URL = "https://a.ntrk2.com/525d22bc-3a76-496a-8115-ee1eb1dbd856";
export const REGISTER_URL = "https://a.ntrk2.com/4a557e9f-5bc1-42ee-a43a-9553469e6451";

export const LOGIN_URL_MAP = {
  "dee99play.com": "https://a.ntrk2.com/917f4b1c-ea78-4357-8b85-63381a4fba21",
  "d99gx.com": "https://a.ntrk2.com/917f4b1c-ea78-4357-8b85-63381a4fba21",
};

export const REGISTER_URL_MAP = {
  "dee99play.com": "https://a.ntrk2.com/241ff509-5e0a-46f8-ad60-ca7c5a06964c",
  "d99gx.com": "https://a.ntrk2.com/241ff509-5e0a-46f8-ad60-ca7c5a06964c",
};

export const FLOATING_BANNER_URL = "https://d99th.com/cs";

export * from "./codes";
export * from "./context";
